export const releases = [
  {
    altText: '',
    buyUrl:
      'https://rossharpermusic.bandcamp.com/album/the-dark-album-remixes-vol-4',
    releaseDate: new Date('6/29/2023'),
    htmlDescription: `<p class="justify-center">The liner notes to Ross Harper‘s The Dark Album Remixes Volume 4 EP describes one of the tracks as “a DJ’s dream come true.” Really, the whole record is that. Starting with Coast2c‘s (also properly known as Sofia Acosta) remix of “Something New,” the EP opens with thumping bass drum and sizzling electro-cymbals designed to yank people onto the dance floor…and then that thick synth-bass hits and there’s no need to yank people onto the dance floor, they are running to it. Developer‘s remix of “Hard Patience” (the “DJ’s dream”) is pulsating, throbbing, sweaty trance that gives you an instant rush. Amorphic‘s remix of “Narcissist” is almost a hypnotizing mantra of bass hits, fast cymbals, and pulsating synths. Finally, DJ Emerson‘s remix of “Deep Life” – a fun track that mixes hand percussion with sliced up vocals sounds, goth-industrial bass, and racing video game synths. All four tracks on this are scalding hot. Don’t miss this if you’re a DJ or just enjoy EDM.</p>`,
    originalImg: '/assets/images/ross-harper.jpeg',
    title: 'Something New - Ross Harper (Coast2c Remix) [City Wall Records]',
  },
  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/album/machine-music-human-dance',
    releaseDate: new Date('3/10/2023'),
    htmlDescription: `<p class="justify-center"> Machine Music, Human Dance is a conceptual EP created for Takahiro Yamamotos performance NOTHINGBEING, which premiered on September 9th, 2022 at T:BA PICA. "Based on philosophical and physical research in the approaches to nothingness as well as extensive discussions and reflections with participating artists about their own sense of existence in society, their needs in life, and their embodied daily experiences, this work will invite the viewers to "be" in the space together as they witness the performers engaging in questions of not thinking, feeling, surrendering, transforming, conjuring, and forgetting" - Excerpt from NOTHINGBEING description.</p>`,
    originalImg: '/assets/images/machine-music-human-dance.jpg',
    title: 'Machine Music, Human Dance [Gestef Records]',
  },
  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/track/proximity',
    releaseDate: new Date('1/6/2023'),
    htmlDescription: `<p class="justify-center"> A melting analog acid ride...</p>`,
    originalImg: '/assets/images/proximity.jpg',
    title: 'Proximity [Gestef Records]',
  },
  {
    altText: '',
    buyUrl: 'https://durolabel.com/track/dead-piano-original-mix',
    releaseDate: new Date('12/16/2022'),
    htmlDescription: `<p class="justify-center">...It’s followed by the collaboration between Theus Mago and the modular synthesis crack, Coast2c. “Dead Piano” a track influenced by rave music and acid techno, with a resonant bass, sharp percussions and an obscure and deep melody.</p>`,
    originalImg: '/assets/images/MuyDuroComp.jpeg',
    title: 'Dead Piano - Theus Mago & Coast2c [DURO]',
  },
  {
    altText: '',
    buyUrl: 'https://faunareve.com/album/morphism-incl-coast2mago-remix',
    releaseDate: new Date('12/9/2022'),
    htmlDescription: `<p class="justify-center">The trip nears the end with the powerful, percussion-driven “Treeball Alpaka”, a track that sculpts a tight passageway of metallic, futuristic sounds and haunting voices with a sense of menace, and finally settles down with an upbeat, lively remix of this last track, like the enactment of a last-minute escape at the speed of light, out of the mind and hands of Coast2Mago, one of Mexican DJ Theus Mago’s many monikers now with modular producer, Coast2c.</p>`,
    originalImg: '/assets/images/Atweed.png',
    title: 'Treeball Alpaka - A-Tweed x Oltrefuturo [Fauna Rêve]',
  },
  {
    altText: '',
    buyUrl: 'https://bedofrosesentertainment.bandcamp.com/album/a-new-age-cat',
    releaseDate: new Date('11/8/2022'),
    htmlDescription: `<p class="justify-center">The debut EP from Wormbox "A New Age Cat" with remixes from Coast2c and Thomass Jackson out via Bed of Roses</p>`,
    originalImg: '/assets/images/wormbox.png',
    title: 'The Defender of Heaven (Coast2c Remix) [Bed of Roses]',
  },
  {
    altText: '',
    buyUrl: 'https://tutu.bandcamp.com/album/de-tu-a-tu-rmxs',
    releaseDate: new Date('04/18/2022'),
    htmlDescription: `<p class="justify-center">Edgar de Ramon’s highly acclaimed debut LP – De Tu A Tu – came out last year under his established TUTU Records. De Tu A Tu is comprised of 8 tracks of ambient-leaning, sci-fi-inspired techno with a lot of Detroit and Acid influences. A year has passed since its release, and the follow-up remixes album is here. De Tu a Tu Remixes – Every original got its remix treatment by 8 artists sharing Edgar’s vision of fresh, innovative, and gnarly techno. Matrixxman, Tensal, Christina Sealey, Camea, Coast2C, Balfa, Gimenö and The Tunegirl, take De Tu A Tu’s techno palette into a whole new level of fierce and mechanical grooves. A perfectly-crafted project by the man behind TUTU Records..</p><br><p class="mx-auto justify-center py-4">Press:</p><ul><li><a href="https://mixmag.es/read/edgar-de-ramon-presenta-el-remix-de-balfa-para-su-deeper-underground-news" rel="noreferrer" target="_blank">MixMag Spain</a></li><li><a href="https://living-techno.com/16971/" rel="noreferrer" target="_blank">Living Techno</a><li><a href="https://viciousmagazine.com/news/edgar-de-ramon-presenta-los-remixes-de-de-tu-a-tu/" rel="noreferrer" target="_blank">Vicious Magazine</a></li></ul>`,
    originalImg: '/assets/images/EdgarDeRamon.jpg',
    title: 'Constant Movement - Edgar de Ramon (Coast2c remix) [De Tu a Tu]',
  },
  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/album/vault',
    releaseDate: new Date('01/26/2021'),
    htmlDescription: `<p class="justify-center">Vault starts with an hypnotic acid drone piece with “Bones”, a one-take live recording of several different synths, followed by “Time is Not Linear”, an exploration of loops created by the evocative sound of modular synthesizers. “Cornelius” plays with textures, samples and noise. The EP ends with the title track “Vault”, which layers live acid sounds on top of the drums.</p><br /><p class="mx-auto justify-center py-4">Press:</p><ul><li><a href="https://ra.co/exchange/556" rel="noreferrer" target="_blank">Resident advisor</a></li><li><a href="https://filtermexico.com/2021/01/27/coast2c-explora-el-minimalismo-del-drone-y-el-techno-en-vault/" rel="noreferrer" target="_blank">Filter Mexico</a></li><li><a href="https://www.youtube.com/watch?v=REBfhwf9UHk"rel="noreferrer" target="_blank">Hate Lab</a></li><li><a href="https://coldexperiment.com/news/coast2c-vault/" rel="noreferrer" target="_blank">Cold Experiment</a></li><li><a href="https://www.techclubbers.com/coast2c-vaul-ep-gestef-records/" rel="noreferrer" target="_blank">Tech Clubbers</a></li><li><a href="http://wickedstyle.neural.it/2020/12/coast2c-vault/" rel="noreferrer" target="_blank">Wicked Style</a></li><li><a href="https://soundcloud.com/thebrvtalistxx/coast2c-time-is-not-linear-gestef-records" rel="noreferrer" target="_blank">The Brvtalist</a></li></ul>`,
    originalImg: '/assets/images/Vault_cover.png',
    title: 'Vault EP [Gestef Records]',
  },

  {
    altText: '',
    buyUrl: 'https://coast2ckm.bandcamp.com/track/mente-maleable',
    releaseDate: new Date('05/17/2021'),
    htmlDescription: `<p class="justify-center">Track release Out via <a href="https://kratermusiclabel.bandcamp.com/" rel=noreferrer target=_blank>Krater Music</a>.</p><p class="justify-center"><a href="https://www.youtube.com/watch?v=R4ivqt5uRr4" rel="noreferrer" target="_blank">Video</a></p>`,

    originalImg: '/assets/images/mente-maleable.png',
    title: 'Mente Maleable [Krater Music]',
  },

  {
    altText: '',
    buyUrl: 'https://iideo.bandcamp.com/album/iideo-a',
    releaseDate: new Date('02/22/2021'),
    htmlDescription: `<p class="justify-center"> This triptych compilation represents a spectrum of artists and musical lineages, touching on ambient, downtempo, electro, techno, breaks, and miscellaneous other strains of rhythm and frequency. It is a humble pleasure to share the work of such a deeply talented roster of artists, both audio and visual. Through the generosity of these contributors all 2021 compilation proceeds will be directed to Fires Igniting the Spirit and the Lavender Rights Project’s WA Black Trans Task Force.</p>`,
    originalImg: '/assets/images/IdeoA.png',
    title: 'Mirror lake [iideo]',
  },

  // {
  //   altText: '',
  // buyUrl: 'https://2tabs.bandcamp.com/',
  //   releaseDate: new Date('08/28/2020'),
  //   htmlDescription: `<p class="justify-center"> “Aquí EP” is the Debut EP effort by 2TABS. This is the music project of Michael Bruce and Coast2c, hosts and resident DJ’s of the Gran Ritmos parties in Portland, OR.</p>`,
  //   originalImg: '/assets/images/aqui.png',
  //   title: 'Aqui EP',
  // },

  {
    altText: '',
    buyUrl: 'https://graintable.bandcamp.com/track/fmmpc-coast2c-remix',
    releaseDate: new Date('08/28/2020'),
    htmlDescription: `<p class="justify-center"> “Portland’s very own Coast2c rounds off proceedings with her take on FMMPC. Part experimental techno, part textured drone, Sofia’s take is like something from Aphex Twin’s Ambient Works distilled through a post Covid-19 lens.."</p>`,
    originalImg: '/assets/images/ransome-note.png',
    title: 'FMMPC - Graintable (Coast2c Remix) [Ransome Note Records]',
  },

  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/album/martes-sounds',
    releaseDate: new Date('09/11/2020'),
    htmlDescription: `<p class="justify-center"> “MARTES SOUNDS EP is a collection of excerpts from a live improv session recorded on November 19, 2019. All sounds by Francisco Lozano and Coast2c</p>`,
    originalImg: '/assets/images/martes-sounds.png',
    title: 'Martes Sounds',
  },

  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/album/something-greater-is-invisible',
    releaseDate: new Date('03/20/2020'),
    htmlDescription: `<p class="justify-center"> “Something Greater is Invisible” is an EP recorded live on March, 19, 2020. These tracks are selected excerpts of a recording of the live set that was going to presented last week at the Portland Art Museum that was canceled due to the COVID-19 lockdown.</p>`,
    originalImg: '/assets/images/something-greater.png',
    title: 'Something Greater Is Invisible ',
  },

  {
    altText: '',
    buyUrl: 'https://coast2c.bandcamp.com/track/live-pierce-b-i-coast2c',
    releaseDate: new Date('03/20/2020'),
    htmlDescription: `<p class="justify-center"> Recorded live on February, 15 ,2020 in collaboration between Pierce B and Coast2c.</p><p class="justify-center"><a href="https://www.youtube.com/watch?v=bV_UATaGpuE" rel="noreferrer" target="_blank">Video</a></p>`,
    originalImg: '/assets/images/pierceb.png',
    title: 'Live ft. Pierce B',
  },
];
