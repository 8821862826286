export const homeHeroImageSlideItems = [
  {
    description: 'Photo by Alexander Pomper',
    url: '/assets/images/coast2c2.png',
  },
  {
    description: 'Photo by Carly Diaz',
    url: '/assets/images/Coast2c-CarlyDiaz2.jpg',
  },
  {
    description: 'Photo by Start evil',
    url: '/assets/images/Coast2c_laFera2.jpg',
  },
  {
    description: 'Photo by Alec Marchant',
    url: '/assets/images/coast2cAlecMarchant.jpg',
  },
];
