export const events = [
  {
    id: 1,
    title: 'Sisters with Transistors Performance and Panel 2021',
    subtitle: 'Screened during KINOWATT Film Festival',
    date: new Date('06/14/21'),
    location: 'University of Pennsylvania | Online ',
    shortDescription:
      'A conversation between Sisters with Transistors director Lisa Rovner, and arts + technology leader Emmy Parker + Live performances by Frae-Frae: Daughter of Drexciya, Alissa DeRubeis, Coast2c and Omniflux.',
    htmlDescription:
      '<p> 5/4 Woman in technology workshop.Tschechisches Zentrum, Berlin. Superbooth 18 - 5/5 Coastc, Zufu and Hexorcismos. Loftus Hall, Berlin. - 5/9 Metamachine Fest, Prague. - 5/10 Opening Synth Library. Prague. - 5/11 Coast2c, Nick Klein and Myako. Herna, Brno. - 5/17 Fluc + Fluc Wanne, Viena. - 5/18 Yucatan, Vilnus. - 5/25 EMS Residency, Stockholm. - 6/2 Kontaktor Post Factum. Depo, Riga. - 6/5 Berlin Community Radio, Berlin.   </p>',
    infoUrl: 'https://www.youtube.com/watch?v=3RELBpccQf0',
    buyUrl: '',
    thumbnail: '/assets/images/sisters-transistors.jpg',
    originalImg: '/assets/images/sisters-transistors.jpg',
  },
  {
    id: 2,
    title: 'Kontaktor 2019',
    subtitle: 'Live Electronik Musik Festival',
    date: new Date('05/31/19'),
    location: 'Riga Electrical Machine Building Works, Latvia',
    price: 35,
    shortDescription:
      'To celebrate forward-pushing live electronic music from left-field techno, seminal electro to nonconformist industrial, bizarre noise and everything in between.',
    htmlDescription:
      '<p>Music and contemporary culture media <a href="https://www.factmag.com/2019/06/27/kontaktor-2019-latvia-review/" target="_blank" rel="noreferrer"> FACT Magazine </a> writes – "Riga really reminds of Berlin, but not the Berlin of 2019. It reminds of the the city back in the early 2000s, when the music scene was fractured, dangerous and urgent. It’s an example of a local scene that’s growing quickly and KONTAKTOR is a festival that is amplifying and elevating its community, as well as exposing it to art from outside their frame of reference."</p>',
    infoUrl: 'https://kontaktor.lv/kontaktor-2019/',
    buyUrl: '',
    thumbnail: '/assets/images/kontaktor.jpeg',
    originalImg: '/assets/images/kontaktor.jpeg',
  },
  {
    id: 3,
    title: 'Europe Tour 2018',
    subtitle: 'DJ tour',
    date: new Date('05/04/18'),
    location: 'Europe',
    shortDescription:
      'Woman in technology workshop, Metamachine Fest, Herna, Loftus Hall, Synth Library Prague, EMS, Berlin Community Radio, Fluc + Fluc Wanne, Yucatan, Kontaktor Post Factum.',
    htmlDescription:
      '<p> 5/4 Woman in technology workshop.Tschechisches Zentrum, Berlin. Superbooth 18 - 5/5 Coastc, Zufu and Hexorcismos. Loftus Hall, Berlin. - 5/9 Metamachine Fest, Prague. - 5/10 Opening Synth Library. Prague. - 5/11 Coast2c, Nick Klein and Myako. Herna, Brno. - 5/17 Fluc + Fluc Wanne, Viena. - 5/18 Yucatan, Vilnus. - 5/25 EMS Residency, Stockholm. - 6/2 Kontaktor Post Factum. Depo, Riga. - 6/5 Berlin Community Radio, Berlin.   </p>',
    infoUrl: '',
    buyUrl: '',
    thumbnail: '/assets/images/europe-tour.png',
    originalImg: '/assets/images/europe-tour.png',
  },
];
